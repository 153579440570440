<template >
  <div class="main_page">
    <main-header></main-header>

    <section class="about">
          <div class="top">
              <div class="container">
                  <div class="row">
                      <div class="left">
                          <div class="text">
                              <p>
                                China Bangladesh Technical Institute (CBTI), stand, kneel, pray, and act with our Black brothers and sisters and all who
                                are oppressed and have felt the dehumanizing impact of racism, invisibility and hate. We send our condolences and
                                prayers to the families of Breonna Taylor, Ahmaud Arbery, and George Floyd and all the families whose loved ones are
                                unnamed. We share your fight and your burden. We will not rest until together, we overcome the systemic, generational
                                and unending brutality practiced against people of color under the authority of those who are sworn to serve and
                                protect.
                              </p>
                          </div>
                      </div>
                      <div class="right">
                          <div class="image">
                              <img src="@/assets/images/about-us.jpg" alt="About">
                          </div>
                          <a href="" class="link_btn">In Memoriam</a>
                      </div>
                  </div>
              </div>
          </div>
          <div class="bottom">
              <div class="container">
                  <div class="row">
                      <div class="left">
                          <div class="link">AIHEC's Statement of Solidarity</div>
                          <ul class="lists">
                              <li class="list">
                                  <a href="" class="link">» Advocacy: TAKE ACTION</a>
                              </li>
                              <li class="list">
                                  <a href="" class="link">» National Native Health Research Training Initiative</a>
                              </li>
                              <li class="list">
                                  <a href="" class="link">» www.NNHRTI.org</a>
                              </li>
                              <li class="list">
                                  <a href="" class="link">» COVID-19: Info & Resources</a>
                              </li>
                              <li class="list">
                                  <a href="" class="link">» COVID-19: TCU Operating Status</a>
                              </li>
                              <li class="list">
                                  <a href="" class="link">» TCU COVID-19 Needs Assessment Report</a>
                              </li>
                          </ul>
                      </div>
                      <div class="right">
                          <div class="text">
                              <p>
                                  “President Bordeaux’s absence will be felt deeply every time we gather, but he left us with a timeless legacy of love, commitment, and strength. That gives me comfort. Please say a prayer for President Bordeaux’s wife, Barbara, his children, grandchildren, and the students, faculty, and staff at the college he loved so profoundly, Sinte Gleska University. I pray that the Holy Ones watch over all of us.”
                              </p>
                          </div>
                          <div class="txt">
                              --Carrie Billy, President and CEO of AIHEC
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>

      <section class="blog">
          <div class="container">
              <div class="row">
                  <div class="cards">
                      <div class="card">
                          <div class="title">
                              <h2>
                                  <a href="">Who We Are</a>
                              </h2>
                          </div>
                          <div class="image">
                              <a href="">
                                  <img src="@/assets/images/gallery/gallery-02.jpeg" alt="Blog One">
                              </a>
                          </div>
                          <div class="text">
                            <p>
                                The CBTI was founded in 2022 by the tribally controlled colleges in China Bangladesh to serve as a support system as
                                they worked to change federal policies regarding higher education in China Bangladesh.
                            </p>
                            <p>
                                Each tribal college was established and granted a charter by its own tribal government or the federal government with
                                the specific intent of giving China Bangladeshis access to higher education through supportive, locally and culturally
                                based, holistic programs.
                            </p>
                          </div>
                      </div>
                      <div class="card">
                          <div class="title">
                              <h2>
                                  <a href="">What We Do</a>
                              </h2>
                          </div>
                          <div class="image">
                              <a href="">
                                  <img src="@/assets/images/gallery/gallery-03.jpeg" alt="Blog Two">
                              </a>
                          </div>
                          <div class="text">
                            <p>
                                Our main job is to advocate. In order to ensure that TCUs are fairly represented in this country's higher education
                                system and that the principle of tribal sovereignty is acknowledged and respected, CBTI is working to advance these
                                goals.
                            </p>
                          </div>
                      </div>
                      <div class="card">
                          <div class="title">
                              <h2>
                                  <a href="">Who We Serve</a>
                              </h2>
                          </div>
                          <div class="image">
                              <a href="">
                                  <img src="@/assets/images/gallery/gallery-04.jpeg" alt="Blog Three">
                              </a>
                          </div>
                          <div class="text">
                            <p>
                                The ten tribes that make up the largest American reservations all have TCUs that are chartered by their respective
                                tribal governments. They serve students from well over 230 federally recognized Indian tribes and run more than 75
                                campuses across 16 states, effectively covering Indian Country.
                            </p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>

      <div class="contact_area" id="contact">
        <div class="contact_info">
            <h2>Get In Touch!</h2>
            <span class="hr"></span>
            <ul class="address">
                <li>info@cbtibd.ac <span>&#8592;</span></li>
                <li>verified@cbtibd.ac <span>&#8592;</span></li>
            </ul>
            <div class="social">
                <a href="#"></a>
            </div>
        </div>
        <form class="contact_form">
            <p class="msg">
            </p>
            <input
                type="text"
                name="fullname"
                class="half"
                placeholder="Full Name"
            />
            <input
                type="number"
                name="phone"
                class="half last"
                placeholder="Phone Number"
            />
            <input
                type="text"
                name="email"
                class="full"
                placeholder="example@gmail.com"
            />
            <input
                type="text"
                name="subject"
                class="full"
                placeholder="Subject"
            />
            <textarea
                rows="5"
                name="message"
                class="full"
                placeholder="message"
            ></textarea>
            <button type="submit" class="button">Send</button>
        </form>
    </div>

    <contact-page></contact-page>
  </div>
</template>
<script>
import MainHeader from "@/modules/user/views/layouts/MainHeader.vue";
import ContactPage from "@/modules/user/views/layouts/MainFooter.vue";

export default {
  components:{ MainHeader, ContactPage },
  setup() {
      
  },
}
</script>
<style lang="scss">
/* About Css */
section.about {}

section.about .top {
    background-color: #3B4346;
    border-top: solid 1px #222628;
    box-shadow: inset 0px 1px 0px 0px #3e484a;
    border-bottom: 2px solid red;
    padding: 30px 0 0;
}

section.about .top .container {
    max-width: 1200px;
    width: 100%;
    margin: auto;
    padding: 0 20px;
}

section.about .top .container .row {
    display: grid;
    grid-template-columns: repeat(2, minmax(200px, 1fr));
    column-gap: 20px;
}

section.about .top .container .row .left {}

section.about .top .container .row .left .logo {
    margin-bottom: 30px;
}

section.about .top .container .row .left .logo .image {}

section.about .top .container .row .left .logo .image img {
    max-width: 200px;
    width: 100%;
}

section.about .top .container .row .left .nav {
    margin-bottom: 8px;
}

section.about .top .container .row .left .nav ul.lists {
    list-style: none;
    display: flex;
    align-items: center;
    column-gap: 20px;
}

section.about .top .container .row .left .nav ul.lists li.list {}

section.about .top .container .row .left .nav ul.lists li.list .link {
    color: #b7d2e3;
    font-size: 15px;
    display: block;
}

section.about .top .container .row .left .search {
    margin-bottom: 30px;
}

section.about .top .container .row .left .search form {
    display: flex;
    align-items: center;
    column-gap: 4px;
}

section.about .top .container .row .left .search form input {
    height: 30px;
    padding: 4px 12px;
}

section.about .top .container .row .left .search form button {
    height: 30px;
    padding: 4px 12px;
    background-color: transparent;
    border: 2px solid #000;
    color: #fff;
}

section.about .top .container .row .left .text {}

section.about .top .container .row .left .text p {
    color: #fff;
    font-size: 20px;
    line-height: 28px;
}

section.about .top .container .row .right {}

section.about .top .container .row .right .image {
    margin-bottom: 8px;
}

section.about .top .container .row .right .image img {
    max-width: 100%;
    width: 100%;
}

section.about .top .container .row .right .link_btn {
    border: 2px solid #ffff;
    padding: 8px 16px;
    display: inline-block;
    color: #fff;
    font-size: 17px;
    text-transform: uppercase;
    text-decoration: none;
    margin-bottom: -2px;
}

section.about .bottom {
    background-color: #2d3335;
    padding: 40px 0;
}

section.about .bottom .container {
    max-width: 1200px;
    width: 100%;
    margin: auto;
    padding: 0 20px;
}

section.about .bottom .container .row {
    display: grid;
    grid-template-columns: repeat(2, minmax(200px, 1fr));
    column-gap: 20px;
}

section.about .bottom .container .row .left {}

section.about .bottom .container .row .left>.link {
    color: #b7d2e3;
    margin-bottom: 20px;
}

section.about .bottom .container .row .left ul.lists {
    list-style: none;
}

section.about .bottom .container .row .left ul.lists li.list {
    line-height: 30px;
}

section.about .bottom .container .row .left ul.lists li.list .link {
    color: #b7d2e3;
    font-size: 18px;
    text-decoration: none;
}

section.about .bottom .container .row .right {}

section.about .bottom .container .row .right .text {
    margin-bottom: 8px;
}

section.about .bottom .container .row .right .text p {
    color: #fff;
    line-height: 28px;
    font-size: 16px;
}

section.about .bottom .container .row .right .txt {
    color: #fff;
    font-size: 16px;
}



/* Blog css */
section.blog {
    background-color: #f7f7f7;
    padding: 40px 0;
}

section.blog .container {
    padding: 0 20px;
}

section.blog .container .row {}

section.blog .container .row .cards {
    display: grid;
    grid-template-columns: repeat(3, minmax(220px, 1fr));
    column-gap: 20px;
}

section.blog .container .row .cards .card {
    background-color: #fff;
    padding: 40px 30px 45px 30px;
    box-shadow: 2px 2px 2px 1px rgb(128 128 128 / 10%);
}

section.blog .container .row .cards .card .title {
    margin-bottom: 8px;
}

section.blog .container .row .cards .card .title h2 {}

section.blog .container .row .cards .card .title h2 a {
    font-size: 24px;
    color: #ca2d13;
    display: block;
}

section.blog .container .row .cards .card .title h2 a:hover {
    text-decoration: none;
}

section.blog .container .row .cards .card .content {
    margin-bottom: 20px;
}

section.blog .container .row .cards .card .content p {
    color: #65899f;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
}

section.blog .container .row .cards .card .image {
    margin-bottom: 12px;
}

section.blog .container .row .cards .card .image a {
    display: block;
    padding: 10px;
    box-shadow: 3px 3px 3px 1px rgb(0 0 0 / 15%);
}

section.blog .container .row .cards .card .image a img {
    max-width: 100%;
    width: 100%;
    height: auto;
    display: block;
}

section.blog .container .row .cards .card .small {
    font-size: 13px;
    margin-bottom: 16px;
}

section.blog .container .row .cards .card .text {
    margin-bottom: 20px;
}

section.blog .container .row .cards .card .text p {
    color: #474f51;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 16px;
}

section.blog .container .row .cards .card .tag {
    margin-bottom: 12px;
    color: #474f51;
    font-size: 15px;
}

section.blog .container .row .cards .card>.link {
    line-height: 40px;
}

section.blog .container .row .cards .card>.link .url {
    color: #ca2d13;
    font-size: 18px;
}

section.blog .container .row .cards .card>.link .url:hover {
    text-decoration: none;
}

section.blog .container .row .cards .card .lists {
    list-style: none;
}

section.blog .container .row .cards .card .lists .list {
    padding: 20px 0;
    border-bottom: 1px solid #e2e6e8;
}

section.blog .container .row .cards .card .lists .list:last-child {
    border-bottom: 0px solid #e2e6e8;
}

section.blog .container .row .cards .card .lists .list>.link {
    color: #ca2d13;
    font-size: 18px;
}

section.blog .container .row .cards .card .lists .list>.link:hover {
    text-decoration: none;
}

/* About Css */
section.about {}

section.about .top {
    background-color: #3B4346;
    border-top: solid 1px #222628;
    box-shadow: inset 0px 1px 0px 0px #3e484a;
    border-bottom: 2px solid red;
    padding: 30px 0 0;
}

section.about .top .container {
    padding: 0 20px;
}

section.about .top .container .row {
    display: grid;
    grid-template-columns: repeat(2, minmax(200px, 1fr));
    column-gap: 20px;
}

section.about .top .container .row .left {}

section.about .top .container .row .left .logo {
    margin-bottom: 30px;
}

section.about .top .container .row .left .logo .image {}

section.about .top .container .row .left .logo .image img {
    max-width: 200px;
    width: 100%;
}

section.about .top .container .row .left .nav {
    margin-bottom: 8px;
}

section.about .top .container .row .left .nav ul.lists {
    list-style: none;
    display: flex;
    align-items: center;
    column-gap: 20px;
}

section.about .top .container .row .left .nav ul.lists li.list {}

section.about .top .container .row .left .nav ul.lists li.list .link {
    color: #b7d2e3;
    font-size: 15px;
    display: block;
}

section.about .top .container .row .left .search {
    margin-bottom: 30px;
}

section.about .top .container .row .left .search form {
    display: flex;
    align-items: center;
    column-gap: 4px;
}

section.about .top .container .row .left .search form input {
    height: 30px;
    padding: 4px 12px;
}

section.about .top .container .row .left .search form button {
    height: 30px;
    padding: 4px 12px;
    background-color: transparent;
    border: 2px solid #000;
    color: #fff;
}

section.about .top .container .row .left .text {}

section.about .top .container .row .left .text p {
    color: #fff;
    font-size: 20px;
    line-height: 28px;
}

section.about .top .container .row .right {}

section.about .top .container .row .right .image {
    margin-bottom: 8px;
}

section.about .top .container .row .right .image img {
    max-width: 100%;
    width: 100%;
}

section.about .top .container .row .right .link_btn {
    border: 2px solid #ffff;
    padding: 8px 16px;
    display: inline-block;
    color: #fff;
    font-size: 17px;
    text-transform: uppercase;
    text-decoration: none;
    margin-bottom: -2px;
}

section.about .bottom {
    background-color: #2d3335;
    padding: 40px 0;
}

section.about .bottom .container {
    padding: 0 20px;
}

section.about .bottom .container .row {
    display: grid;
    grid-template-columns: repeat(2, minmax(200px, 1fr));
    column-gap: 20px;
}

section.about .bottom .container .row .left {}

section.about .bottom .container .row .left>.link {
    color: #b7d2e3;
    margin-bottom: 20px;
}

section.about .bottom .container .row .left ul.lists {
    list-style: none;
}

section.about .bottom .container .row .left ul.lists li.list {
    line-height: 30px;
}

section.about .bottom .container .row .left ul.lists li.list .link {
    color: #b7d2e3;
    font-size: 18px;
    text-decoration: none;
}

section.about .bottom .container .row .right {}

section.about .bottom .container .row .right .text {
    margin-bottom: 8px;
}

section.about .bottom .container .row .right .text p {
    color: #fff;
    line-height: 28px;
    font-size: 16px;
}

section.about .bottom .container .row .right .txt {
    color: #fff;
    font-size: 16px;
}



/* Blog css */
section.blog {
    background-color: #f7f7f7;
    padding: 40px 0;
}

section.blog .container {
    max-width: 1200px;
    width: 100%;
    margin: auto;
    padding: 0 20px;
}

section.blog .container .row {}

section.blog .container .row .cards {
    display: grid;
    grid-template-columns: repeat(3, minmax(220px, 1fr));
    column-gap: 20px;
}

section.blog .container .row .cards .card {
    background-color: #fff;
    padding: 40px 30px 45px 30px;
    box-shadow: 2px 2px 2px 1px rgb(128 128 128 / 10%);
}

section.blog .container .row .cards .card .title {
    margin-bottom: 8px;
}

section.blog .container .row .cards .card .title h2 {}

section.blog .container .row .cards .card .title h2 a {
    font-size: 24px;
    color: #ca2d13;
    display: block;
}

section.blog .container .row .cards .card .title h2 a:hover {
    text-decoration: none;
}

section.blog .container .row .cards .card .content {
    margin-bottom: 20px;
}

section.blog .container .row .cards .card .content p {
    color: #65899f;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
}

section.blog .container .row .cards .card .image {
    margin-bottom: 12px;
}

section.blog .container .row .cards .card .image a {
    display: block;
    padding: 10px;
    box-shadow: 3px 3px 3px 1px rgb(0 0 0 / 15%);
}

section.blog .container .row .cards .card .image a img {
    max-width: 100%;
    width: 100%;
    height: auto;
    display: block;
}

section.blog .container .row .cards .card .small {
    font-size: 13px;
    margin-bottom: 16px;
}

section.blog .container .row .cards .card .text {
    margin-bottom: 20px;
}

section.blog .container .row .cards .card .text p {
    color: #474f51;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 16px;
}

section.blog .container .row .cards .card .tag {
    margin-bottom: 12px;
    color: #474f51;
    font-size: 15px;
}

section.blog .container .row .cards .card>.link {
    line-height: 40px;
}

section.blog .container .row .cards .card>.link .url {
    color: #ca2d13;
    font-size: 18px;
}

section.blog .container .row .cards .card>.link .url:hover {
    text-decoration: none;
}

section.blog .container .row .cards .card .lists {
    list-style: none;
}

section.blog .container .row .cards .card .lists .list {
    padding: 20px 0;
    border-bottom: 1px solid #e2e6e8;
}

section.blog .container .row .cards .card .lists .list:last-child {
    border-bottom: 0px solid #e2e6e8;
}

section.blog .container .row .cards .card .lists .list>.link {
    color: #ca2d13;
    font-size: 18px;
}

section.blog .container .row .cards .card .lists .list>.link:hover {
    text-decoration: none;
}



/* ======================================== */
/* Contact Css */
.contact_area {
    display: flex;
    background-color: #fff;
    padding: 30px 60px;
    .contact_form,
    .contact_info {
        flex: 6;
    }
    .contact_info {
        text-align: right;
        padding-right: 28px;
        span.hr {
            width: 100px;
            height: 2px;
            background-color: #444;
            display: inline-block;
            margin: 12px 0;
        }
        .address {
            list-style: none;
            li {
                color: #444;
                line-height: 28px;
                span {
                    font-size: 18px;
                }
            }
        }
    }
    .contact_form {
        input, textarea {
            padding: 12px;
            border: 1px solid #d8d8d8;
            margin-bottom: 16px;
            border-radius: 4px;
            outline: none;
            &:focus {
                border: 1px solid #adca36;
            }
            &.valid {
                border-color: tomato;
            }
        }
        .half {
            width: 48%;
            float: left;
            margin-right: 4%;
            &.last {
                margin-right: 0;
            }
        }
        .full {
            width: 100%;
        }
        .button {
            border: none;
            outline: none;
            cursor: pointer;
            color: #222;
            font-weight: 600;
            padding: 12px 20px;
            border-radius: 4px;
            display: inline-block;
            background: linear-gradient(45deg, #ffb1b1, #adc744);
            transition: all 0.3s ease 0s;
            -webkit-transition: all 0.3s ease 0s;
            &:hover {
                background: linear-gradient(45deg, #ff9e9e, #adca36);
                transition: all 0.3s ease 0s;
                -webkit-transition: all 0.3s ease 0s;
            }
        }
    }
}








/* ======================================== */
/* Resposive Css */

@media only screen and (max-width: 991px) {
    
}

@media only screen and (max-width: 767px) {
    
}

@media only screen and (max-width: 575px) {
    section.about .top .container .row {
        grid-template-columns: 1fr;
        row-gap: 20px;
    }
    section.about .bottom .container .row {
        grid-template-columns: 1fr;
        row-gap: 20px;
    }
    section.blog .container .row .cards {
        grid-template-columns: 1fr;
        row-gap: 20px;
    }
}

@media only screen and (max-width: 375px) {}
</style>
 
