<template>
    <header class="header">
        <div class="container">
            <div class="row">
                <!-- <h4>TRIBAL Colleges: <span>Educating, Engaging, Innovating, Sustaining, Honoring</span></h4> -->
                <div class="navbar">
                    <div class="logo">
                        <div class="toggle">
                            <div class="btn">
                                <span class="line"></span>
                                <span class="line"></span>
                                <span class="line"></span>
                            </div>
                        </div>
                        <router-link to="/" class="link">
                            <img src="@/assets/images/logo.jpeg" alt="Logo">
                            <div class="title">China Bangladesh Technical Institute</div>
                        </router-link>
                        <ul class="lists">
                            <li class="list" v-if="student_id">
                                <router-link to="" class="link" @click="Logout">Logout</router-link>
                            </li>
                            <li class="list" v-else>
                                <router-link to="/login" class="link">Login</router-link>
                            </li>
                        </ul>
                    </div>
                    
                    <div class="nav">
                        <div class="heading">
                            <div class="_l">
                                <h2>Menu</h2>
                            </div>
                            <div class="_r">
                                <div class="close_btn">&#9747;</div>
                            </div>
                        </div>
                        <ul class="lists">
                            <li class="list">
                                <router-link to="/" class="link">Home</router-link>
                            </li>
                            <li class="list">
                                <router-link to="/whoweare" class="link">About</router-link>
                            </li>
                            <li class="list">
                                <router-link to="/whoweserve" class="link">Courses</router-link>
                            </li>
                            <li class="list">
                                <router-link to="/gallery" class="link">Gallery</router-link>
                            </li>
                            <li class="list">
                                <router-link to="/admission" class="link">Admission Form</router-link>
                            </li>
                            <li class="list">
                                <router-link to="/contact" class="link">Contact</router-link>
                            </li>
                            <li class="list" v-if="student_id">
                                <router-link to="/profile" class="link">Profile</router-link>
                            </li>
                            <li class="list" v-if="student_id">
                                <router-link to="" class="link" @click="Logout">Logout</router-link>
                            </li>
                            <li class="list" v-else>
                                <router-link to="/login" class="link">Student Login</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="site_overlay"></div>
    </header>
</template>
<script>
import AuthStore from "@/store/AuthStore.js"

export default {
    setup() {
        const { id, student_id, name, Logout } = AuthStore();


        return {
            id:id(),
            student_id:student_id(),
            name:name(),
            Logout
        }
    },

    mounted(){
        let toggle = document.querySelector(".toggle .btn");
        let overlay = document.querySelector(".header .site_overlay");
        let nav = document.querySelector(".navbar .nav");
        let nav_close_btn = document.querySelector(".navbar .nav .heading ._r .close_btn");

        toggle.addEventListener("click", function(){
            nav.classList.add("show");
            overlay.classList.add("show");
        });

        overlay.addEventListener("click", function(){
            nav.classList.remove("show");
            this.classList.remove("show");
        });
        nav_close_btn.addEventListener("click", function(){
            nav.classList.remove("show");
            overlay.classList.remove("show");
        });


        const box_item = document.querySelectorAll(".wrapper .left .boxs .box h2");
        for (let i = 0; i < box_item.length; i++) {
            box_item[i].addEventListener("click", function() {
                this.classList.toggle("active");
                let box_items = this.nextElementSibling;
                if (box_items.style.maxHeight) {
                    box_items.style.maxHeight = null;
                } else {
                    box_items.style.maxHeight = box_items.scrollHeight + "px";
                } 
            });
        }
    }
}
</script>

<style>
body {
    font-family: sans-serif;
}
/* Header */
header.header {
    background-color: #fff;
    box-shadow: inset 0px -1px 0px 0px #51575a;
    padding: 20px 0;
}

header.header .container {
    max-width: 1200px;
    width: 100%;
    margin: auto;
    padding: 0 20px;
}

header.header .container .row {}

header.header .container .row h4 {
    text-align: right;
    color: #0b8cc9;
    font-size: 14px;
    font-weight: 500;
}

header.header .container .row h4 span {
    font-style: italic;
}

header.header .container .row .navbar {
    display: flex;
    align-items: center;
}

header.header .container .row .navbar .logo {
    width: 28%;
}

header.header .container .row .navbar .logo .toggle,
header.header .container .row .navbar .logo ul.lists {
    display: none;
}

header.header .container .row .navbar .logo .link {
    display: flex;
    align-items: center;
    column-gap: 20px;
    text-decoration: none;
    font-size: 18px;
}

header.header .container .row .navbar .logo .title {
    font-size: 24px;
    color: #0b8cc9;
}

header.header .container .row .navbar .logo img {
    max-width: 80px;
    width: 100%;
}

header.header .container .row .navbar .nav {
    width: 72%;
}

header.header .container .row .navbar .nav .heading {
    display: none;
}

header.header .container .row .navbar .nav ul.lists {
    list-style: none;
    display: flex;
    justify-items: center;
    justify-content: flex-end;
    column-gap: 16px;
}

header.header .container .row .navbar .nav ul.lists .list {}

header.header .container .row .navbar .nav ul.lists .list .link {
    text-decoration: none;
    /* text-transform: uppercase; */
    color: #000;
    font-size: 20px;
}
header.header .container .row .navbar .nav ul.lists .list .link:hover,
header.header .container .row .navbar .nav ul.lists .list .link.active,
 header.header .container .row .navbar .navul.lists .list .router-link-active {
    color: #ca2d13;
}




/* ======================================== */
/* Resposive Css */

@media only screen and (max-width: 991px) {

    header.header .container .row .navbar .logo .toggle,
    header.header .container .row .navbar .logo ul.lists {
        display: block;
    }

    header.header .container .row h4 {
        display: none;
    }

    header.header .container .row .navbar .nav {
        display: none;
    }

    header.header .container .row .navbar .nav .heading {
        display: block;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 4px 12px;
        border-bottom: 1px solid #ddd;
    }

    header.header .container .row .navbar .nav .heading ._l {}

    header.header .container .row .navbar .nav .heading ._l h2 {
        font-size: 20px;
        color: #444;
    }

    header.header .container .row .navbar .nav .heading ._r {}

    header.header .container .row .navbar .nav .heading ._r .close_btn {
        padding: 4px;
    }

    header.header .container .row .navbar .logo {
        width: 100%;
        display: flex;
        align-items: center;
    }

    header.header .container .row .navbar .logo .toggle {
        width: 15%;
    }

    header.header .container .row .navbar .logo .toggle .btn {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
    }

    header.header .container .row .navbar .logo .toggle .btn .line {
        width: 20px;
        height: 2px;
        background-color: #000;
        display: inline-block;
    }

    header.header .container .row .navbar .logo>.link {
        width: 70%;
    }

    header.header .container .row .navbar .logo ul.lists {
        width: 15%;
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: end;
    }

    header.header .container .row .navbar .logo ul.lists .list {}

    header.header .container .row .navbar .logo ul.lists .list .link {
        font-size: 15px;
        color: #000;
        font-weight: 600;
        text-decoration: none;
        text-transform: uppercase;
        text-align: right;
    }

    header.header .container .row .navbar .logo .link {
        justify-content: center;
    }

    header.header .container .row .navbar .logo .title {
        width: 200px;
    }


    header.header .container .row .navbar .nav {
        display: block;
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        width: 0;
        background-color: #fff;
        box-shadow: 0 4px 20px rgba(0, 0, 0, .15);
        overflow: hidden;
        transition: all 0.3s ease 0s;
        -webkit-transition: all 0.3s ease 0s;
        z-index: 999;
    }

    header.header .container .row .navbar .nav.show {
        width: 240px;
    }

    header.header .container .row .navbar .nav.show ul.lists {
        display: block;
    }

    header.header .container .row .navbar .nav.show ul.lists .list {}

    header.header .container .row .navbar .nav.show ul.lists .list .link {
        text-decoration: none;
        text-transform: uppercase;
        color: #000;
        font-size: 18px;
        padding: 12px 8px;
        display: block;
    }

    header.header .container .row .navbar .nav.show ul.lists .list .link:hover,
    header.header .container .row .navbar .nav.show ul.lists .list .link.active,
    header.header .container .row .navbar .nav.show ul.lists .list .router-link-active {
        color: #ca2d13;
    }







    footer.footer .container .row .widgets {
        flex-direction: column;
    }

    footer.footer .container .row .widgets .widget {
        width: 100% !important;
    }

    footer.footer .container .row .widgets .widget .heading {}

    footer.footer .container .row .widgets .widget .heading h4 {
        position: relative;
        cursor: pointer;
    }

    footer.footer .container .row .widgets .widget .heading h4:after {
        content: '\002B';
        color: #777;
        font-weight: bold;
        float: right;
        margin-left: 5px;
    }

    footer.footer .container .row .widgets .widget .heading h4.active:after {
        content: "\2212";
    }

    footer.footer .container .row .widgets .widget .heading h4.active {
        margin-bottom: 12px;
    }

    footer.footer .container .row .widgets .widget .content .lists {
        padding: 0 18px;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.4s ease-out;
        margin-bottom: 20px;
    }








    header.header .site_overlay.show {
        position: fixed;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.192);
        top: 0;
        left: 0;
        z-index: 998;
    }
}

@media only screen and (max-width: 767px) {}

@media only screen and (max-width: 575px) {
    header.header .container .row .navbar .logo>.link {
        column-gap: 6px;
    }

    header.header .container .row .navbar .logo img {
        max-width: 55px;
    }

    header.header .container .row .navbar .logo .title {
        width: 180px;
        font-size: 20px;
    }

    header.header .container .row .navbar .logo ul.lists .list .link {
        font-size: 13px;
    }

    header.header .container .row .navbar .logo .toggle .btn .line {
        height: 1px;
    }
}

@media only screen and (max-width: 375px) {}
</style>