const UserModules = () => import("./UserModules.vue");
const Home = () => import("./views/HomePage.vue");
const Gallery = () => import("./views/GalleryPage.vue");
const Admission = () => import("./views/AdmissionPage.vue");
const Login = () => import("./views/LoginPage.vue");
const User = () => import("./views/UserPage.vue");
const Result = () => import("./views/result/ResultPage.vue");

const AdvocatePage = () => import("./views/whoweare/AdvocatePage.vue");
const BoardPage = () => import("./views/whoweare/BoardPage.vue");
const BylawsPage = () => import("./views/whoweare/BylawsPage.vue");
const CalendarPage = () => import("./views/whoweare/CalendarPage.vue");
const CodePage = () => import("./views/whoweare/CodePage.vue");
const HistoryPage = () => import("./views/whoweare/HistoryPage.vue");
const HomeWhoPage = () => import("./views/whoweare/HomeWhoPage.vue");
const MembershipPage = () => import("./views/whoweare/MembershipPage.vue");
const MessagePage = () => import("./views/whoweare/MessagePage.vue");
const VisionPage = () => import("./views/whoweare/VisionPage.vue");

const HomeServePage = () => import("./views/whoweserve/HomeServePage.vue");

const AnnouncePage = () => import("./views/whatwedo/AnnouncePage.vue");
const GovRelationsPage = () => import("./views/whatwedo/GovRelationsPage.vue");
const GrantsPage = () => import("./views/whatwedo/GrantsPage.vue");
const HomeDoPage = () => import("./views/whatwedo/HomeDoPage.vue");
const InitiativesPage = () => import("./views/whatwedo/InitiativesPage.vue");

const OurstoriesPage = () => import("./views/ourstories/OurstoriesPage.vue");


const ContactPage = () => import("./views/ContactPage.vue");



const moduleRoute = {
    path: "/",
    component: UserModules,
    children: [
      { 
        path:"/", 
        name:"home",
        component:Home,
        // meta: {requiredLogin: false}
      },
      { 
        path:"/login", 
        name:"login",
        component:Login,
        // meta: {requiredLogin: false}
      },
      { 
        path:"/profile", 
        name:"user",
        component:User,
        meta: {requiredLogin: true}
      },
      { 
        path:"/result", 
        name:"result",
        component:Result,
        meta: {requiredLogin: true}
      },

      { 
        path:"/gallery", 
        name:"gallery",
        component:Gallery,
        // meta: {requiredLogin: false}
      },
      { 
        path:"/admission", 
        name:"admission",
        component:Admission,
        // meta: {requiredLogin: false}
      },
      { 
        path:"/whoweare", 
        name:"whoweare",
        component:HomeWhoPage,
        // meta: {AdminLogin: true}
      },
      { 
        path:"/whoweare/advocate", 
        name:"whoweare-advocate",
        component:AdvocatePage,
        // meta: {AdminLogin: true}
      },
      { 
        path:"/whoweare/board", 
        name:"whoweare-board",
        component:BoardPage,
        // meta: {AdminLogin: true}
      },
      { 
        path:"/whoweare/bylaws", 
        name:"whoweare-bylaws",
        component:BylawsPage,
        // meta: {AdminLogin: true}
      },
      { 
        path:"/whoweare/calendar", 
        name:"whoweare-calendar",
        component:CalendarPage,
        // meta: {AdminLogin: true}
      },
      { 
        path:"/whoweare/code", 
        name:"whoweare-code",
        component:CodePage,
        // meta: {AdminLogin: true}
      },
      { 
        path:"/whoweare/history", 
        name:"whoweare-history",
        component:HistoryPage,
        // meta: {AdminLogin: true}
      },
      { 
        path:"/whoweare/membership", 
        name:"whoweare-membership",
        component:MembershipPage,
        // meta: {AdminLogin: true}
      },
      { 
        path:"/whoweare/message", 
        name:"whoweare-message",
        component:MessagePage,
        // meta: {AdminLogin: true}
      },
      { 
        path:"/whoweare/vision", 
        name:"whoweare-vision",
        component:VisionPage,
        // meta: {AdminLogin: true}
      },

    // Whoweserve
      { 
        path:"/whoweserve", 
        name:"whoweserve",
        component:HomeServePage,
        // meta: {AdminLogin: true}
      },

      // Whatwedo
      { 
        path:"/whatwedo", 
        name:"whatwedo",
        component:HomeDoPage,
        // meta: {AdminLogin: true}
      },
      { 
        path:"/whatwedo/announce", 
        name:"whatwedo-announce",
        component:AnnouncePage,
        // meta: {AdminLogin: true}
      },
      { 
        path:"/whatwedo/gov-relations", 
        name:"whatwedo-gov-relations",
        component:GovRelationsPage,
        // meta: {AdminLogin: true}
      },
      { 
        path:"/whatwedo/grants", 
        name:"whatwedo-grants",
        component:GrantsPage,
        // meta: {AdminLogin: true}
      },
      { 
        path:"/whatwedo/initiatives", 
        name:"whatwedo-initiatives",
        component:InitiativesPage,
        // meta: {AdminLogin: true}
      },

      // Ourstories
      { 
        path:"/ourstories", 
        name:"ourstories ",
        component:OurstoriesPage,
        // meta: {AdminLogin: true}
      },
      { 
        path:"/contact", 
        name:"contact ",
        component:ContactPage,
        // meta: {AdminLogin: true}
      },
    ]
  };
  
  export default router => {
    router.addRoute(moduleRoute);
  };