import { createWebHistory, createRouter } from "vue-router";
import HomePage from "@/modules/user/views/HomePage.vue";

const routes = [
  {
    path: "/",
    name: "home-page",
    component: HomePage,
    // meta: {requiredLogin: false}
  },
];

const router = new createRouter({
  history:createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  if(to.meta.AdminLogin){
      if(!localStorage.getItem('admin_id')){
          next({
              path:'/admin/login',
          });
      }else{
          next();
      }
  }else{
      next();
  }
})
router.beforeEach((to, from, next) => {
  if(to.meta.requiredLogin){
    if(!localStorage.getItem('id')){
      next({
          path:'/login',
      });
    }else{
        next();
    }
  }else{
      next();
  }

})



export default router;