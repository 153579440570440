<template>
    <footer class="footer">
        <div class="container">
            <div class="row">
                <div class="widgets">
                    <div class="widget">
                        <div class="heading">
                            <h4>Our Portals</h4>
                        </div>
                        <div class="content">
                            <ul class="lists">
                                <li class="list">
                                    <a href="#" class="link">CBTIBD AIMS</a>
                                </li>
                                <li class="list">
                                    <a href="#" class="link">CBTIBD Indigenous Evaluation</a>
                                </li>
                                <li class="list">
                                    <a href="#" class="link">CBTIBD STEM Resources</a>
                                </li>
                                <li class="list">
                                    <a href="#" class="link">Chief Academic Officers</a>
                                </li>
                                <li class="list">
                                    <a href="#" class="link">FALCON</a>
                                </li>
                                <li class="list">
                                    <a href="#" class="link">Student Success Collaborative</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="widget">
                        <div class="heading">
                            <h4>Our STORIES</h4>
                        </div>
                        <div class="content">
                            <ul class="lists">
                                <li class="list"><a href="#" class="link">Accreditation</a></li>
                                <li class="list"><a href="#" class="link">Advancing Native Knowledge</a></li>
                                <li class="list"><a href="#" class="link">Health Wellness</a></li>
                                <li class="list"><a href="#" class="link">Land Place</a></li>
                                <li class="list"><a href="#" class="link">Measures of Success</a></li>
                                <li class="list"><a href="#" class="link">Native Languages Culture</a></li>
                                <li class="list"><a href="#" class="link">Return on Investment</a></li>
                                <li class="list"><a href="#" class="link">Student Success</a></li>
                                <li class="list"><a href="#" class="link">The TCU Movement</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="widget">
                        <div class="heading">
                            <h4>Our PARTNERSHIPS</h4>
                        </div>
                        <div class="content">
                            <ul class="lists">
                                <li class="list">
                                    <a href="#" class="link">CBTIBD AIMS</a>
                                </li>
                                <li class="list">
                                    <a href="#" class="link">CBTIBD Indigenous Evaluation</a>
                                </li>
                                <li class="list">
                                    <a href="#" class="link">CBTIBD STEM Resources</a>
                                </li>
                                <li class="list">
                                    <a href="#" class="link">Chief Academic Officers</a>
                                </li>
                                <li class="list">
                                    <a href="#" class="link">FALCON</a>
                                </li>
                                <li class="list">
                                    <a href="#" class="link">Student Success Collaborative</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="widget">
                        <div class="heading">
                            <h4>Our CONNECTIONS</h4>
                        </div>
                        <div class="content">
                            <ul class="lists social">
                                <li class="list">
                                    <a href="#" class="link">
                                        <img src="@/assets/images/social/facebook.png" alt="Facebook">
                                    </a>
                                </li>
                                <li class="list">
                                    <a href="#" class="link">
                                        <img src="@/assets/images/social/instagram.png" alt="Instagram">
                                    </a>
                                </li>
                                <li class="list">
                                    <a href="#" class="link">
                                        <img src="@/assets/images/social/twitter.png" alt="Twitter">
                                    </a>
                                </li>
                                <li class="list">
                                    <a href="#" class="link">
                                        <img src="@/assets/images/social/youtube.png" alt="Youtube">
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="widget">
                        <div class="heading">
                            <h4>Our CONSORTIUM</h4>
                        </div>
                        <div class="content">
                            <ul class="lists">
                                <li class="list">
                                    <a href="#" class="link">CBTIBD AIMS</a>
                                </li>
                                <li class="list">
                                    <a href="#" class="link">CBTIBD Indigenous Evaluation</a>
                                </li>
                                <li class="list">
                                    <a href="#" class="link">CBTIBD STEM Resources</a>
                                </li>
                                <li class="list">
                                    <a href="#" class="link">Chief Academic Officers</a>
                                </li>
                                <li class="list">
                                    <a href="#" class="link">FALCON</a>
                                </li>
                                <li class="list">
                                    <a href="#" class="link">Student Success Collaborative</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="copyright">
                    <p>&copy; 2023 CBTIBD. All rights reserved.</p>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
export default {
    mounted(){

        const footer_item = document.querySelectorAll(".footer .widgets .widget .heading h4");
        for (let i = 0; i < footer_item.length; i++) {
            footer_item[i].addEventListener("click", function() {
                this.classList.toggle("active");
                let footer_items = this.closest('.widget').querySelector('.content .lists');
                console.log(footer_items);
                if (footer_items.style.maxHeight) {
                    footer_items.style.maxHeight = null;
                } else {
                    footer_items.style.maxHeight = footer_items.scrollHeight + "px";
                } 
            });
        }


    }
}
</script>
<style>
/* Footer Css */
footer.footer {
    background-color: #d4d9dd;
    box-shadow: inset 0px -1px 0px 0px #d4d9dd;
    padding: 40px 0;
}

footer.footer .container {
    max-width: 1200px;
    width: 100%;
    margin: auto;
    padding: 0 20px;
}

footer.footer .container .row {}

footer.footer .container .row .widgets {
    /* display: flex; */
    display: none;
    column-gap: 20px;
}

footer.footer .container .row .widgets .widget:nth-child(1),
footer.footer .container .row .widgets .widget:nth-child(2),
footer.footer .container .row .widgets .widget:nth-child(3),
footer.footer .container .row .widgets .widget:nth-child(4) {
    width: 19%;
}

footer.footer .container .row .widgets .widget:nth-child(5) {
    width: 24%;
}

footer.footer .container .row .widgets .widget .heading {}

footer.footer .container .row .widgets .widget .heading h4 {
    color: #212f35;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
}

footer.footer .container .row .widgets .widget .content {
    margin-top: 16px;
}

footer.footer .container .row .widgets .widget .content ul.lists {
    list-style: none;
}

footer.footer .container .row .widgets .widget .content ul.lists .list {
    line-height: 34px;
    border-top: 1px solid #e0e4e6;
    border-bottom: 1px solid #b5bec3;
}

footer.footer .container .row .widgets .widget .content ul.lists .list .link {
    color: #546b76;
    font-size: 14px;
}

footer.footer .container .row .widgets .widget .content ul.lists .list .link:hover {
    text-decoration: none;
}

footer.footer .container .row .widgets .widget .content ul.lists.social .list .link img {
    margin-top: 8px;
    width: 22px;
}

footer.footer .container .row .copyright {
    padding: 40px 0;
}

footer.footer .container .row .copyright p {
    font-size: 12px;
    text-align: center;
    color: #8d9ca3;
    text-shadow: 1px 1px 0px rgb(255 255 255 / 50%);
}
</style>