import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
// import store from "./store";
import axios from 'axios';


axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.baseURL = process.env.VUE_APP_API_URL;



import userModule from "@/modules/user";
import adminAuthModule from "@/modules/admin-auth";
import adminModule from "@/modules/admin";

import { registerModules } from "./register-modules";


registerModules({
    user: userModule,
    adminauth: adminAuthModule,
    admin: adminModule,
});


const app = createApp(App);
app.use(router);
app.config.productionTip = false;
app.mount('#app');


