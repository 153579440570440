// import router from "@/router";
import axios from "axios";

const { reactive, readonly, computed } = require("vue");

const state = reactive({
    id:localStorage.getItem('id')?localStorage.getItem('id'):'',
    student_id:localStorage.getItem('student_id')?localStorage.getItem('student_id'):'',
    name:localStorage.getItem('name')?localStorage.getItem('name'):'',
})

const getters = {
    id : () => {
        return computed({
            get(){ return state.id },
            set(value){ return state.id = value },
        })
    },
    student_id : () => {
        return computed({
            get(){ return state.student_id },
            set(value){ return state.student_id = value },
        })
    },
    name : () => {
        return computed({
            get(){ return state.name },
            set(value){ return state.name = value },
        })
    },
}

const mutations = {
    
}

const actions = {
    LoginSubmit : () => {
        axios.post('/api/login',{
            student_id:state.student_id
        })
        .then(response => {
            localStorage.setItem('id',response.data.data.id);
            localStorage.setItem('student_id',response.data.data.student_id);
            localStorage.setItem('name',response.data.data.name);
            localStorage.setItem('_token',response.data._token);
            window.location.href = "/profile";
        })
    },
    Logout : () => {
        localStorage.removeItem('id');
        localStorage.removeItem('student_id');
        localStorage.removeItem('name');
        localStorage.removeItem('_token');
        window.location.href = "/";
    }
}

export default () => {
    return {
        state:readonly(state),
        ...getters,
        ...mutations,
        ...actions,
    }
}
