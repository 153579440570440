const AdminModule = () => import("./AdminModule.vue");
const Home = () => import("./views/HomePage.vue");
// Category
const CategoryList = () => import("./views/category/CategoryList.vue");
const CategoryAdd = () => import("./views/category/CategoryAdd.vue");
const CategoryEdit = () => import("./views/category/CategoryEdit.vue");
// Slider
const SliderList = () => import("./views/slider/SliderList.vue");
const SliderAdd = () => import("./views/slider/SliderAdd.vue");
const SliderEdit = () => import("./views/slider/SliderEdit.vue");
// Service
const ServiceList = () => import("./views/service/ServiceList.vue");
const ServiceAdd = () => import("./views/service/ServiceAdd.vue");
const ServiceEdit = () => import("./views/service/ServiceEdit.vue");
// Service
const SubjectList = () => import("./views/subject/SubjectList.vue");
const SubjectAdd = () => import("./views/subject/SubjectAdd.vue");
const SubjectEdit = () => import("./views/subject/SubjectEdit.vue");
// Student
const StudentList = () => import("./views/student/StudentList.vue");
const StudentAdd = () => import("./views/student/StudentAdd.vue");
const StudentEdit = () => import("./views/student/StudentEdit.vue");
// Semester
const SemesterList = () => import("./views/semester/SemesterList.vue");
const SemesterAdd = () => import("./views/semester/SemesterAdd.vue");
const SemesterEdit = () => import("./views/semester/SemesterEdit.vue");
// Department
const DepartmentList = () => import("./views/department/DepartmentList.vue");
const DepartmentAdd = () => import("./views/department/DepartmentAdd.vue");
const DepartmentEdit = () => import("./views/department/DepartmentEdit.vue");
// Result
const ResultList = () => import("./views/result/ResultList.vue");
const ResultAdd = () => import("./views/result/ResultAdd.vue");
const ResultEdit = () => import("./views/result/ResultEdit.vue");





const moduleRoute = {
  path: "/admin",
  component: AdminModule,
  children: [
    { 
      path:"/admin", 
      name:"admin-home ",
      component:Home,
      meta: {AdminLogin: true}
    },
    // category
    { 
      path:"/admin/category", 
      name:"admin-category",
      component:CategoryList,
      meta: {AdminLogin: true}
    },
    { 
      path:"/admin/category/add", 
      name:"admin-category-add",
      component:CategoryAdd,
      meta: {AdminLogin: true}
    },
    { 
      path:"/admin/category/edit/:id", 
      name:"admin-category-edit",
      component:CategoryEdit,
      meta: {AdminLogin: true}
    },
    // slider
    { 
      path:"/admin/slider", 
      name:"admin-slider",
      component:SliderList,
      meta: {AdminLogin: true}
    },
    { 
      path:"/admin/slider/add", 
      name:"admin-slider-add",
      component:SliderAdd,
      meta: {AdminLogin: true}
    },
    { 
      path:"/admin/slider/edit/:id", 
      name:"admin-slider-edit",
      component:SliderEdit,
      meta: {AdminLogin: true}
    },
    // service
    { 
      path:"/admin/service", 
      name:"admin-service",
      component:ServiceList,
      meta: {AdminLogin: true}
    },
    { 
      path:"/admin/service/add", 
      name:"admin-service-add",
      component:ServiceAdd,
      meta: {AdminLogin: true}
    },
    { 
      path:"/admin/service/edit/:id", 
      name:"admin-service-edit",
      component:ServiceEdit,
      meta: {AdminLogin: true}
    },
    // Subject
    { 
      path:"/admin/subject", 
      name:"admin-subject",
      component:SubjectList,
      meta: {AdminLogin: true}
    },
    { 
      path:"/admin/subject/add", 
      name:"admin-subject-add",
      component:SubjectAdd,
      meta: {AdminLogin: true}
    },
    { 
      path:"/admin/subject/edit/:id", 
      name:"admin-subject-edit",
      component:SubjectEdit,
      meta: {AdminLogin: true}
    },
    // Student
    { 
      path:"/admin/student", 
      name:"admin-student",
      component:StudentList,
      meta: {AdminLogin: true}
    },
    { 
      path:"/admin/student/add", 
      name:"admin-student-add",
      component:StudentAdd,
      meta: {AdminLogin: true}
    },
    { 
      path:"/admin/student/edit/:id", 
      name:"admin-student-edit",
      component:StudentEdit,
      meta: {AdminLogin: true}
    },
    // Semester
    { 
      path:"/admin/semester", 
      name:"admin-semester",
      component:SemesterList,
      meta: {AdminLogin: true}
    },
    { 
      path:"/admin/semester/add", 
      name:"admin-semester-add",
      component:SemesterAdd,
      meta: {AdminLogin: true}
    },
    { 
      path:"/admin/semester/edit/:id", 
      name:"admin-semester-edit",
      component:SemesterEdit,
      meta: {AdminLogin: true}
    },
    // Department
    { 
      path:"/admin/department", 
      name:"admin-department",
      component:DepartmentList,
      meta: {AdminLogin: true}
    },
    { 
      path:"/admin/department/add", 
      name:"admin-department-add",
      component:DepartmentAdd,
      meta: {AdminLogin: true}
    },
    { 
      path:"/admin/department/edit/:id", 
      name:"admin-department-edit",
      component:DepartmentEdit,
      meta: {AdminLogin: true}
    },
    // Result
    { 
      path:"/admin/result", 
      name:"admin-result",
      component:ResultList,
      meta: {AdminLogin: true}
    },
    { 
      path:"/admin/result/add", 
      name:"admin-result-add",
      component:ResultAdd,
      meta: {AdminLogin: true}
    },
    { 
      path:"/admin/result/edit/:id", 
      name:"admin-result-edit",
      component:ResultEdit,
      meta: {AdminLogin: true}
    },
  ]
};

export default router => {
  router.addRoute(moduleRoute);
};

